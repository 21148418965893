<template>
    <div class="bg-red-500">
        <div class="container mx-auto">
            <header class="flex items-center justify-between px-sm sm:px-0">
                <nuxt-link :to="{ name: 'index' }"
                    :aria-label="t('storyblok.home')"
                    class="size-[50px] h-full py-1">
                    <v-icon icon="logo"
                        class="text-[50px] text-white" />
                </nuxt-link>

                <nuxt-link :to="{ name: 'cart' }"
                    :aria-label="t('back-to-cart')"
                    class="text-white hover:underline">
                    {{ t('back-to-cart') }}
                </nuxt-link>
            </header>
        </div>
    </div>
</template>

<script setup lang="ts">
const { t } = useI18n();

useCustomerLogin();
</script>

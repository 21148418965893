<template>
    <v-slim-header />

    <v-toast-container></v-toast-container>

    <slot></slot>

    <v-footer />
</template>

<script lang="ts" setup>
const { t } = useI18n();

const siteTitle = t('masku-url');

useSeoMeta({
    titleTemplate(title) {
        return title ? `${title} / ${siteTitle}` : siteTitle;
    },
});

useDefaultDynamicHead();
</script>
